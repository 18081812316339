import { type MetaFunction } from '@remix-run/node'

export const meta: MetaFunction = () => {
	return [
		{ title: 'Roqlogic Media Center' },
		{ name: 'description', content: 'Roqlogic Media Center' },
	]
}

export default function Index() {
	return <div>Hello World</div>
}
